import { MouseEvent, useEffect, useRef, useState } from 'react';
import Container from '@/components/Container';
import NextLink from 'next/link';
import NextImage from 'next/image';
import { CgScrollV } from 'react-icons/cg';
import { SlArrowDown } from 'react-icons/sl';

function PresentationSection2() {
	const handleClickScrollIcon = (ev: MouseEvent<HTMLButtonElement>) => {
		console.log(window.innerWidth, window.innerHeight);

		window.scrollTo({
			top: window.innerHeight - 128,
			behavior: 'smooth',
		});
	};

	const textsRef = useRef<HTMLDivElement>(null);
	const frases = [
		`<div>
			<p style="font-size:1.25rem;">
				<span style="color: #ed5d5d; font-size:2.5rem; line-height: 2rem; font-weight:bolder;">+ de 70 mil</span>
				<br />
				<span>pessoas estão</span>
				<strong>aguardando por um transplante</strong>&nbsp;de órgão e/ou tecido
				no Brasil
				<p style="font-size:0.875rem">(Dados do Ministério da Saúde - abril/2024)</p>
			</p>
		</div>
		`,
		`<div>
			<p style="font-size:1.25rem;">
				<span style="color: #ed5d5d; font-size:2.5rem; line-height: 2rem; font-weight:bolder;">+ de 9 mil</span>
				<br />
				<span>pessoas receberam um transplante de</span>
				<strong>órgão sólido</strong> no Brasil em 2023
			</p>
			<p style="font-size:0.875rem">(Dados do Ministério da Saúde - abril/2024)</p>
		</div>
		`,
		`<div>
			<p style="font-size:1.25rem;">
				<span style="color: #ed5d5d; font-size:2.5rem; line-height: 2rem; font-weight:bolder;">+ de 41%</span>
				<br />
				<span>das <strong>famílias brasileiras negaram</strong> a doação de órgãos e tecidos de um ente querido em 2023</span>
			</p>
			<p style="font-size:0.875rem">(Dados da ABTO - RBT Ano XXX Nº 4)</p>
		</div>
		`,
		// '+ de 41% das famílias brasileiras negaram a doação de órgãos e tecidos de um ente querido em 2023 (Dados da ABTO - RBT Ano XXX Nº 4)',
	];

	useEffect(() => {
		const delay = () => new Promise((resolve) => setTimeout(resolve));
		let i = 0;
		textsRef.current.innerHTML = frases[i];

		let interval = setInterval(() => {
			if (i == frases.length - 1) {
				i = 0;
			} else {
				i++;
			}
			console.log(i);
			textsRef.current.innerHTML = frases[i];
		}, 3000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<div className="min-h-[100svh-12rem)] md:min-h-[calc(100svh-40rem)] flex flex-col justify-between w-full relative object-fit">
			<figure>
				<picture>
					<source
						srcSet="https://res.cloudinary.com/oliveiradev/image/upload/v1712933680/InstitutoDeixeVivo/sections/imagem-pagina-inicial-mobile-sem-texto.png"
						media="(min-width: 0px) and (max-width: 991.99px)"
					/>
					<source
						srcSet="https://res.cloudinary.com/oliveiradev/image/upload/v1712933680/InstitutoDeixeVivo/sections/imagem-pagina-inicial-desktop-sem-texto.png"
						media="(min-width: 1200px)"
					/>

					<img
						tabIndex={0}
						className="w-full h-[calc(1080px-30rem)] lg:h-[calc(1080px-344px)] object-cover"
						src="https://res.cloudinary.com/oliveiradev/image/upload/v1712933680/InstitutoDeixeVivo/sections/imagem-pagina-inicial-desktop-sem-texto.png"
						aria-label="Métricas sobre a lista de transplante"
					/>
				</picture>
				<figcaption className="invisible text-xs text-center mt-2">
					Métricas sobre a Lista de transplante
				</figcaption>
			</figure>

			<div
				ref={textsRef}
				className="w-full absolute z-30 bottom-8 px-4 py-4 lg:bottom-0 lg:top-32 lg:right-12 lg:w-[48rem] lg:h-max"
			/>
		</div>
	);
}

export default function PresentationSection() {
	return (
		<div className="w-full">
			<Container className="py-8">
				<h2 className="text-3xl font-bold mb-8" tabIndex={0}>
					O que nos motiva a trabalhar pela causa...
				</h2>
				<section className="lg:min-h-[416px] shadow-xl">
					<video
						className=" mx-auto aspect-video border-0"
						autoPlay
						loop
						muted
						src="https://res.cloudinary.com/oliveiradev/video/upload/v1718414736/InstitutoDeixeVivo/videos/video-idv.mp4"
					></video>
				</section>

				<div>

				</div>
			</Container>
		</div>
	);
}

/* 
<Container className="min-h-[calc(100vh-12rem)] md:min-h-[calc(100vh-9rem)] flex flex-col justify-between">
			<section
				id="presentation-section"
				className="w-full py-8 flex flex-col items-center gap-y-12 lg:justify-between lg:flex-nowrap lg-landscape:py-0 lg:landscape:py-0 lg:flex-row flex-1"
				aria-label="Seja bem-vindo(a) ao nosso site"
				aria-describedby="#heading-one"
			>
				<div className="flex flex-col w-full lg:w-3/5 gap-y-4">
					<h1
						id="heading-one"
						className="lg:mb-2 font-bold text-4xl lg:text-5xl landscape:text-2xl lg:landscape:text-5xl"
						tabIndex={0}
					>
						Instituto Deixe Vivo
					</h1>

					<p className="font-bold text-2xl" tabIndex={0}>
						<span className="text-primary-dark">Informação</span>,{' '}
						<span className="text-primary-dark">Inspiração</span> e{' '}
						<span className="text-primary-dark">Acolhimento</span>
					</p>

					<p aria-label="texto descritivo" tabIndex={0}>
						Nosso trabalho é focado em conscientizar toda a sociedade sobre a
						doação de órgãos e tecidos, inspirando cada vez mais pessoas a se
						declararem doadoras. Além disso, atuamos no acolhimento de pacientes
						renais crônicos, pessoas em lista de espera para transplante,
						transplantados e seus familiares todos por meio da informação.
					</p>

					<NextLink
						aria-label="Link para página de apoio ao Instituto Deixe Vivo"
						tabIndex={0}
						className={'button rounded-lg w-max text-uppercase'}
						href="/sobre-o-instituto/nossa-historia"
					>
						<strong>Saiba mais!</strong>
					</NextLink>
				</div>

				<div className="self-center">
					<figure>
						<picture>
							<source
								// src="/assets/images/illustrations/undraw_personal_file.svg"
								srcSet="https://res.cloudinary.com/oliveiradev/image/upload/v1684379329/InstitutoDeixeVivo/imagem-pagina-inicial.png"
								media="(min-width: 0px)"
							/>

							<NextImage
								loading="lazy"
								unoptimized
								tabIndex={0}
								className="ml-auto lg:flex w-[416px] lg:w-[416px] h-auto float-right"
								src="https://res.cloudinary.com/oliveiradev/image/upload/v1684379329/InstitutoDeixeVivo/imagem-pagina-inicial.png"
								alt="Informamos, inspiramos e acolhemos pessoas que estejam a espera de um transplante"
								width={256}
								height={256}
								aria-label="Ilustração de uma mulher carregando um documento"
							/>
						</picture>
						<figcaption className="invisible text-xs text-center mt-2">
							Informamos, inspiramos e acolhemos pessoas que estejam a espera de
							um transplante
						</figcaption>
					</figure>
				</div>
			</section>

			<div className={`hidden md:flex justify-center animate-bounce-slow pb-2`}>
				<button
					title="Navegar para o carrossel"
					onClick={handleClickScrollIcon}
					className="group rounded-full p-2 border-b-2 bg-primary-dark text-white active:scale-90 hover:bg-primary-dark  shadow-sm flex items-center"
				>
					<SlArrowDown className="text-2xl  fill-white group-hover:fill-white duration-300" />
				</button>
			</div>
		</Container>
*/
