import { ReactNode, MouseEvent, MouseEventHandler } from 'react';
import NextLink from 'next/link';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useAccessibilityStore } from '@/contexts/accessibility';

export type CardContentLinkProps = {
	content: string;
	ariaLabel: string;
	title: string;
	href?: string;
	icon?: ReactNode;
	target?: string
	onClick?: MouseEventHandler<HTMLAnchorElement>
};
export default function CardContentLink({
	content,
	title,
	href,
	icon,
	ariaLabel,
	target,
	onClick
}: CardContentLinkProps) {

	return (
		<div
			className="flex flex-col items-center justify-between w-full md:w-1/2 lg:w-3/12 h-60 py-4"
			tabIndex={0}
			aria-label={ariaLabel}
		>
			<div className="h-20 flex flex-col gap-y-2 items-center w-full justify-center">
				{icon}
				<strong className="text-center w-full h-18">{title}</strong>
			</div>
			<p className="w-[70%] flex items-center h-20 justify-center text-center">
				{content}
			</p>

			<NextLink
				href={href}
				// className={`text-white bg-primary-dark lg:bg-[unset] lg:text-black flex items-center gap-x-4 py-1 px-2 hover:bg-primary-dark group duration-300 w-full md:w-max justify-center rounded-lg`}
				className="button rounded-lg text-white"
				aria-label={'Link da página de medicamentos' + content}
				onClick={onClick}
				target={target}
			>
				<span className="font-bold uppercase group-hover:text-white group-hover:transition-colors duration-300">
					Acessar
				</span>
				{/* <ArrowRightAltIcon
					className="lg:text-primary-dark lg:group-hover:text-white group-hover:transition-colors text-white ml-2"
				/> */}
			</NextLink>
		</div>
	);
}
