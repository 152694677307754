import { ExtendProps } from 'app';
import { HTMLAttributes } from 'react';

export default function HorizontalRow(
	props: ExtendProps<HTMLAttributes<HTMLHRElement>>
) {
	const { className } = props;

	return (
		<hr className={`mt-2 w-[100%] bg-primary-dark h-[2px] ${className ? className : ''}`} />
	);
}
