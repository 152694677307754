import NextImage from 'next/image';
import { useState } from 'react';

import CardContentLink from './CardContentLink';
import HorizontalRow from './HorizontalRow';
import { MdMic, MdVolunteerActivism, MdMedication, MdEmail } from 'react-icons/md';
import { BsPostcardFill } from "react-icons/bs";
import { ModalBoletimInformativo } from './modals/BoletimInformativo';
export default function CardContentContainer() {
	const [isOpenNewsletterForm, setIsOpenNewsLetterForm] = useState(false);

	return (
		<>
			<div className="flex items-center justify-center flex-wrap gap-y-4 gax-x-[1rem] py-8 mt-8">
				<CardContentLink
					content="Saiba tudo o que acontece no #idv"
					title="Boletim Informativo"
					ariaLabel="Cadastre-se em nosso boletim informativo e saiba tudo que acontece no #idv"
					href="/boletim-informativo"
					target='_blank'
					icon={<MdEmail className="text-5xl text-primary-dark" />}
					// onClick={(ev) => {
					// 	ev.preventDefault();
					// 	setIsOpenNewsLetterForm(true);
					// }}
				/>

				<HorizontalRow className="lg:hidden" />

				<CardContentLink
					content="Assista ao nosso Podcast"
					title="Podcast"
					ariaLabel="Assista ao nosso Podcast"
					href="https://youtube.com/@deixevivo"
					icon={<MdMic className="text-5xl text-primary-dark" />}
					target="_blank"
				/>

				<HorizontalRow className="lg:hidden" />

				<CardContentLink
					content="Leia nossos conteúdos do blog"
					title="Blog"
					ariaLabel="Leia nossos conteúdos do blog"
					href="https://blog.deixevivo.org.br"
					icon={<BsPostcardFill className="text-5xl text-primary-dark" />}
					target="_blank"
				/>

				<HorizontalRow className="lg:hidden" />

				<CardContentLink
					content="Crie seu cartão doador personalizado"
					title="Cartão Doador"
					ariaLabel="Crie seu cartão doador personalizado e declare-se doador(a) de órgãos"
					href="/cartao-doador"
					icon={
						<NextImage
							unoptimized
							alt="Logo do Deixe Vivo"
							src="/assets/images/logos/logo-deixe-vivo.png"
							className="w-[3rem] h-[3rem]"
							width={48}
							height={48}
						/>
					}
				/>

				<HorizontalRow className="lg:hidden" />

				<CardContentLink
					content="Saiba o que fazer na falta de medicamentos"
					title="Medicamentos"
					ariaLabel="Saiba o que fazer na falta de medicamentos"
					href="/informacoes/falta-de-medicamentos"
					icon={<MdMedication className="text-5xl text-primary-dark" />}
				/>

				<HorizontalRow className="lg:hidden" />

				<CardContentLink
					content="Aprenda sobre doação de órgãos e tecidos"
					title="FAQ sobre Doação de Órgãos e Tecidos"
					ariaLabel="Aprenda sobre doação de órgãos e tecidos"
					href="/informacoes/doacao-de-orgaos"
					icon={<MdVolunteerActivism className="text-5xl text-primary-dark" />}
				/>
			</div>

			{/* {isOpenNewsletterForm && (
				<ModalBoletimInformativo
					isOpen={isOpenNewsletterForm}
					setIsOpen={setIsOpenNewsLetterForm}
				/>
			)} */}
		</>
	);
}
