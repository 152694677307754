import { ExtendProps } from 'app';
import Head from 'next/head';

type PageHeadProps = {
	page?: {
		title?: string;
		description?: string;
		keywords?: string;
		colorScheme?: string;
		robots?: string;
	};
	og?: {
		title?: string;
		type?: string;
		description?: string;
		site_name?: string;
		url?: string;
		image?: {
			src?: string;
			type?: string;
			height?: string;
			width?: string;
			alt?: string;
			fbAdmins?: string;
		};
	};
	twitter?: {
		card?: string;
		site?: string;
		url?: string;
		title?: string;
		description?: string;
		image?: {
			src?: string;
			alt?: string;
		};
	};
};
export default function PageHead(props: ExtendProps<Partial<PageHeadProps>>) {
	const { og, page, twitter } = props;

	return (
		<Head>
			<title>{page.title}</title>
			<meta charSet="utf-8" />
			<meta httpEquiv="text/html; charset=utf-8" />
			<meta name="author" content="OliveiraDev - https://oliveiradev.com.br" />

			<meta name="robots" content={page.robots} />
			<meta name="description" content={page.description} />
			<meta name="keywords" content={page.keywords} />
			<meta name="color-scheme" content={page.colorScheme} />

			{/* Open Graph Tags */}
			<meta property="og:locale" content="pt_BR" />
			<meta property="og:title" content={og?.title || ''} />
			<meta property="og:type" content={og?.type || ''} />
			<meta property="og:description" content={og?.description || ''} />
			<meta property="og:site_name" content={og?.site_name || ''} />
			<meta property="og:url" content={og?.url || ''} />
			<meta property="og:image" content={og?.image.src || ''} />
			<meta property="og:image:type" content={og?.image.type || ''} />
			<meta property="og:image:height" content={og?.image.height || ''} />
			<meta property="og:image:width" content={og?.image.width || ''} />
			<meta property="og:image:alt" content={og?.image.alt || ''} />
			<meta property="fb:admins" content={og?.image.fbAdmins || ''} />

			{/* Twitter Meta Tags */}
			<meta name="twitter:card" content={twitter?.card || ''} />
			<meta name="twitter:site" content={twitter?.description || ''} />
			<meta name="twitter:url" content={twitter?.url || ''} />
			<meta name="twitter:title" content={twitter?.title || ''} />
			<meta name="twitter:description" content={twitter?.description || ''} />
			<meta name="twitter:image" content={twitter?.image.src || ''} />
			<meta name="twitter:image:alt" content={twitter?.image.alt || ''} />
		</Head>
	);
}
