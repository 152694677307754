import { MouseEvent } from 'react';
import Container from '@/components/Container';
import NextLink from 'next/link';
import NextImage from 'next/image';
import { CgScrollV } from 'react-icons/cg';
import { SlArrowDown } from 'react-icons/sl';

export default function PresentationSection2() {
	const handleClickScrollIcon = (ev: MouseEvent<HTMLButtonElement>) => {
		console.log(window.innerWidth, window.innerHeight);

		window.scrollTo({
			top: window.innerHeight - 128,
			behavior: 'smooth',
		});
	};

	// text-4xl lg:text-5xl
	// min-h-[calc(100vh-12rem)] md:min-h-[calc(100vh-9rem)]
	return (
		<Container className="py-16 flex flex-col justify-between">
			<section
				id="presentation-section"
				className="w-full py-2 flex flex-col items-center gap-y-12 lg:justify-between lg:flex-nowrap lg-landscape:py-0 lg:landscape:py-0 lg:flex-row flex-1"
				aria-label="Seja bem-vindo(a) ao nosso site"
				aria-describedby="#heading-one"
			>
				<div className="flex flex-col gap-y-2">
					<h2
						id="heading-one"
						className="lg:mb-2 font-bold text-3xl lg:text-3xl landscape:text-2xl lg:landscape:text-3xl"
						tabIndex={0}
					>
						Conheça o Instituto
					</h2>

					<p aria-label="texto descritivo" tabIndex={0}>
						Nosso trabalho é focado em conscientizar toda a sociedade sobre a
						doação de órgãos e tecidos, inspirando cada vez mais pessoas a se
						declararem doadoras. Além disso, atuamos no acolhimento de pacientes
						renais crônicos, pessoas em lista de espera para transplante,
						transplantados e seus familiares todos por meio da informação.
					</p>

					<NextLink
						aria-label="Link para página de apoio ao Instituto Deixe Vivo"
						tabIndex={0}
						className={'button rounded-lg w-max text-uppercase'}
						href="/sobre-o-instituto/nossa-historia"
					>
						<strong>Saiba mais!</strong>
					</NextLink>
				</div>
			</section>

			{/* <div className={`hidden md:flex justify-center animate-bounce-slow pb-2`}>
				<button
					title="Navegar para o carrossel"
					onClick={handleClickScrollIcon}
					className="group rounded-full p-2 border-b-2 bg-primary-dark text-white active:scale-90 hover:bg-primary-dark  shadow-sm flex items-center"
				>
					<SlArrowDown className="text-2xl  fill-white group-hover:fill-white duration-300" />
				</button>
			</div> */}
		</Container>
	);
}
