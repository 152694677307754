import { MdArrowLeft, MdArrowRight } from 'react-icons/md';
import NextHead from 'next/head';
import NextImage from 'next/image';
import { ExtendProps } from 'app';
import { CustomArrowProps } from 'react-slick';

export function PrevArrow(props: ExtendProps<CustomArrowProps>) {
	return (
		<button
			onClick={props.onClick}
			className="w-6 h-6 z-10 left-0 -translate-y-1/2 top-1/2 absolute bg-primary-dark flex justify-center items-center text-white text-3xl"
			title="Voltar à imagem anterior"
		>
			<MdArrowLeft />
		</button>
	);
}
export function NextArrow(props: ExtendProps<CustomArrowProps>) {
	return (
		<button
			onClick={props.onClick}
			className="w-6 h-6 z-50 right-0 -translate-y-1/2 top-1/2 absolute bg-primary-dark flex justify-center items-center text-white text-3xl"
			title="Ir para a próxima imagem"
		>
			<MdArrowRight />
		</button>
	);
}
