import PartnershipCard from '@/components/PartnershipCard';
import Slider, { Settings, CustomArrowProps } from 'react-slick';
import NextHead from 'next/head';
import { NextArrow, PrevArrow } from '@/components/CarouselArrows';

export function CarouselPartnerships() {
	const settings: Settings = {
		dots: false,
		infinite: true,
		adaptiveHeight: false,
		variableWidth: true,
		autoplay: true,
		speed: 2000,
		easing: 'linear',
		edgeFriction: 0,
		pauseOnFocus: true,
		pauseOnHover: true,
		slidesToScroll: 2,
		swipeToSlide: true,
		accessibility: true,
		touchMove: true,
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
	};

	return (
		<>
			<div className="w-full overflow-hidden py-4 bg-white">
				<p className="text-2xl text-center font-bold mb-8">
					Apoiadores e Parceiros
				</p>

				<Slider className="flex items-center cursor-grab" {...settings}>
					{/* <PartnershipCard
						link={{
							title:
								'Acessar site da Associação Brasileira de Captadores de Recursos',
							href: 'https://captadores.org.br/',
						}}
						image={{
							ariaLabel: 'Logo da Universidade de Sâo Caetano do Sul',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1690755291/InstitutoDeixeVivo/parceiros/abcr.jpg',
							maxWidth: '128px',
						}}
						position="Apoiador"
						title="ABCR"
					/> */}

					<PartnershipCard
						link={{
							title: 'Acessar site da ABTO',
							href: 'https://site.abto.org.br/',
						}}
						image={{
							ariaLabel:
								'Logo da Associação Brasileira de Transplante de Órgãos',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1682537475/InstitutoDeixeVivo/apoiadores/abto.png',
							maxWidth: '256px',
						}}
						position="Apoiador"
						title="ABTO"
					/>

					<PartnershipCard
						link={{
							title: 'Acessar site da ABTx',
							href: 'https://www.abtx.com.br/',
						}}
						image={{
							ariaLabel: 'Logo da Associação Brasileira de Transplantados',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1682537476/InstitutoDeixeVivo/apoiadores/abtx.png',
							maxWidth: '256px',
						}}
						position="Apoiador"
						title="ABTx"
					/>

					<PartnershipCard
						link={{
							title: 'Acessar site da Cantina Babbo Américo',
							href: 'http://cantinababboamerico.com.br/cantina/',
						}}
						image={{
							ariaLabel: 'Logo da Cantina Babbo Américo',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1682537476/InstitutoDeixeVivo/apoiadores/babbo-americo.png',
							maxWidth: '256px',
						}}
						position="Apoiador"
						title="Cantina Babbo Américo"
					/>

					<PartnershipCard
						link={{
							title:
								'Acessar site da Agência de Transporte do Estado de São Paulo',
							href: 'http://www.artesp.sp.gov.br/',
						}}
						image={{
							ariaLabel: 'Logo da Agência de Transporte do Estado de São Paulo',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1690755291/InstitutoDeixeVivo/parceiros/artesp.jpg',
							maxWidth: '192px',
						}}
						position="Apoiador"
						title="ARTESP"
					/>

					<PartnershipCard
						link={{
							title: 'Acessar site do Centro Universitário São Camilo',
							href: 'https://saocamilo-sp.br/',
						}}
						image={{
							ariaLabel: 'Logo do Centro Universitário São Camilo',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1682537537/InstitutoDeixeVivo/parceiros/centro-universitario-sao-camilo.png',
							maxWidth: '192px',
						}}
						position="Parceiro"
						title="Centro Universitário São Camilo"
					/>

					<PartnershipCard
						link={{
							title: 'Acessar perfil do Instagram do Clube da Empatia',
							href: 'https://www.instagram.com/oclubedaempatia/',
						}}
						image={{
							ariaLabel: 'Logo do Clube da Empatia',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1682537537/InstitutoDeixeVivo/parceiros/clube-da-empatia.png',
							maxWidth: '192px',
						}}
						position="Parceiro"
						title="Clube da Empatia"
					/>

					<PartnershipCard
						link={{
							title: 'Acessar site da Escola da Empatia',
							href: 'https://www.escoladeempatia.com.br/',
						}}
						image={{
							ariaLabel: 'Logo da Escola da Empatia',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1682537537/InstitutoDeixeVivo/parceiros/escola-da-empatia.webp',
							maxWidth: '192px',
						}}
						position="Parceiro"
						title="Escola da Empatia"
					/>

					<PartnershipCard
						link={{
							title: 'Acessar site da Felsberg Advogados',
							href: 'https://www.felsberg.com.br/',
						}}
						image={{
							ariaLabel: 'Logo da Felsberg Advogados',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1682537476/InstitutoDeixeVivo/apoiadores/felsberg-advogados.jpg',
							maxWidth: '256px',
						}}
						position="Apoiador"
						title="Felsberg Advogados"
					/>

					<PartnershipCard
						link={{
							title: 'Acessar site da GBA Contabilidade',
							href: 'https://gbacont.com.br/',
						}}
						image={{
							ariaLabel: 'Logo da GBA Contabilidade',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1682537475/InstitutoDeixeVivo/apoiadores/gba-contabilidade.png',
							maxWidth: '128px',
						}}
						position="Apoiador"
						title="GBA Contabilidade"
					/>

					<PartnershipCard
						link={{
							title: 'Acessar site do Grupo JCA',
							href: 'https://jcaholding.com.br/',
						}}
						image={{
							ariaLabel: 'Logo do Grupo JCA',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1682537537/InstitutoDeixeVivo/parceiros/grupo-jca.png',
							maxWidth: '192px',
						}}
						position="Parceiro"
						title="Grupo JCA"
					/>

					<PartnershipCard
						link={{
							title: 'Acessar site do Guia de Itapetininga',
							href: 'https://guiaitapetininga.com.br/',
						}}
						image={{
							ariaLabel: 'Logo do Guia de Itapetininga',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1684332730/InstitutoDeixeVivo/apoiadores/Logo-Guia-Itapetininga-Azul-300x142.png',
							maxWidth: '192px',
						}}
						position="Parceiro"
						title="Guia de Itapetininga"
					/>

					<PartnershipCard
						link={{
							title: 'Acessar site da Prefeitura de Santos',
							href: 'https://www.santos.sp.gov.br/',
						}}
						image={{
							ariaLabel: 'Logo da Prefeitura de Santos',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1690755291/InstitutoDeixeVivo/parceiros/prefeitura-santos.jpg',
							maxWidth: '128px',
						}}
						position="Apoiador"
						title="Prefeitura de Santos"
					/>

					<PartnershipCard
						link={{
							title: 'Acessar site da Rádio Super Difusora',
							href: 'https://www.radiosuperdifusora.com.br/',
						}}
						image={{
							ariaLabel: 'Logo da Rádio Super Difusora',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1684287632/InstitutoDeixeVivo/apoiadores/radio-super-difusora.png',
							maxWidth: '192px',
						}}
						position="Parceiro"
						title="Rádio Super Difusora"
					/>

					<PartnershipCard
						link={{
							title: 'Acessar site da s-lab',
							href: 'https://s-lab.tech/',
						}}
						image={{
							ariaLabel: 'Logo da s-lab',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1720836611/InstitutoDeixeVivo/parceiros/logo_s_lab_001_principal.png',
							maxWidth: '192px',
						}}
						position="Parceiro"
						title="s-lab"
					/>

					<PartnershipCard
						link={{
							title: 'Acessar site da Unimed Sul Paulista',
							href: 'https://www.unimed.coop.br/site/web/sulpaulista',
						}}
						image={{
							ariaLabel: 'Logo da Unimed Sul Paulista',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1684287631/InstitutoDeixeVivo/apoiadores/unimed-sul-paulista.png',
							maxWidth: '192px',
						}}
						position="Apoiador"
						title="Unimed Sul Paulista"
					/>

					<PartnershipCard
						link={{
							title: 'Acessar site da Universidade Metropolitana de Santos',
							href: 'https://portal.unimes.br/',
						}}
						image={{
							ariaLabel: 'Logo da Universidade Metropolitana de Santos',
							src: 'https://res.cloudinary.com/oliveiradev/image/upload/v1690755291/InstitutoDeixeVivo/parceiros/unimes-universidade-metropolitana-santos.jpg',
							maxWidth: '192px',
						}}
						position="Apoiador"
						title="UNIMES"
					/>
				</Slider>
			</div>
		</>
	);
}
