import Link from 'next/link';

export function SocialInvestorSection() {
	return (
		<div className="w-full py-4 bg-white">
			<h2 className="text-center text-2xl font-bold mb-4">Investidor Social</h2>
			<Link target="_blank" href="https://www.takeda.com/pt-br">
				<img
					title="Acessar o site da Takeda Pharmaceutical Company"
					src="/assets/images/logos/Dakiyama_2021_Red_DIGITAL.svg"
					className="h-auto w-[24rem] mx-auto"
					// style={{ height: 'auto', width: 'auto' }}
				/>
			</Link>
		</div>
	);
}
