import { ExtendProps } from 'app';
import NextImage from 'next/image';
import NextLink from 'next/link';
import { CSSProperties } from 'react';

export default function PartnershipCard({
	image,
	position,
	title,
	link
}: ExtendProps<{
	title: string;
	position: string;
	image: {
		ariaLabel: string;
		maxWidth: CSSProperties['maxWidth'];
		src: string;
	};
	link: {
		href: string;
		title: string;
	};
}>) {
	return (
		<div className="mx-8 md:mx-16">
			<NextLink href={link.href} target="_blank" title={link.title}>
				<figure className="h-[200px] flex flex-col justify-between">
					<figcaption className="text-center font-bold text-sm py-2 hidden ">
						<span className="block">{position}</span>
						<span className="block text-lg whitespace-nowrap">{title}</span>
					</figcaption>
					<picture className="flex flex-row items-center h-full">
						<source srcSet={image.src} media="(max-width: 0px)" />
						<NextImage
							className={`select-none [filter:grayscale2(100%)] h-auto mx-auto [background-blend-mode:screen]`}
							aria-label={image.ariaLabel}
							alt={image.ariaLabel}
							src={image.src}
							width={256}
							height={256}
							style={{ maxWidth: image.maxWidth }}
						/>
					</picture>
				</figure>
			</NextLink>
		</div>
	);
}